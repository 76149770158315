const config = {
  apiKey: 'AIzaSyDLG9Gr26FfAXvk8FGImQnwJ4lwPBNq814',
  authDomain: 'codebee-281912.firebaseapp.com',
  databaseURL: 'https://codebee-281912.firebaseio.com',
  projectId: 'codebee-281912',
  storageBucket: 'codebee-281912.appspot.com',
  messagingSenderId: '1076870891903',
  appId: '1:1076870891903:web:45a547c4865c3470e9724a',
  measurementId: 'G-7FQSJELZ2B',
};

let firebaseInstance: any;
export const getFirebase = (firebase) => {
  if (firebaseInstance) {
    return firebaseInstance;
  }

  firebase.initializeApp(config);
  firebaseInstance = firebase;

  return firebase;
};

// export const useAnalytics = () => {
//   const [instance, setInstance] = React.useState(null);
//   // let analyticsInstance: Analytics;
//   React.useEffect(() => {
//     const lazyApp = import('firebase/app');
//     const lazyanalytics = import('firebase/analytics');
//     Promise.all([lazyApp, lazyanalytics]).then(([firebase, analytics]) => {
//       debugger;
//       const app = getFirebase(firebase).getApp();
//       const analyticsInstance = analytics.getAnalytics(app);
//       setInstance(analyticsInstance);
//       // analytics.logEvent(analyticsInstance, 'page_view');
//     });
//   }, []);
//   const logAnalyticsEvent = (
//     eventName: string,
//     eventParams?: {
//       [key: string]: any;
//       page_title?: string;
//       page_location?: string;
//       page_path?: string;
//     },
//     options?: AnalyticsCallOptions
//   ) => {
//     if (instance) {
//       logEvent(instance, eventName, eventParams, options);
//     }
//   };
//   return { logEvent: logAnalyticsEvent };
// };
